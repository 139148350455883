export default function HeaderMenu() {
    const   headerClass = 'header',
            headerBtnClass = '.js-header-menu',
            headerNav = document.querySelector(headerClass),
            headerScrolledClass = `${headerClass}-scrolled`,
            submenuClass = 'header__submenu',
            headerBtn = document.querySelector(headerBtnClass);
    window.addEventListener('scroll', () => handleScroll(headerNav, headerScrolledClass, submenuClass));

    headerBtn.addEventListener('click', () => {
        headerBtn.classList.toggle('active');
        document.querySelector(`.${headerClass}__menu`).classList.toggle('active');
    });

    document.querySelectorAll(`.${headerClass}__menu a`).forEach((link) => {
        link.addEventListener('click', () => {
            if (headerBtn.classList.contains('active')) {
                headerBtn.classList.remove('active');
                document.querySelector(`.${headerClass}__menu`).classList.toggle('active');
            }
            document.querySelectorAll(`.${submenuClass}`).forEach((submenu) => {
                if (submenu.classList.contains('active')) {
                    submenu.classList.remove('active');
                }
            })
        })
    })
} 
let lastScrollPosition = 0;
const handleScroll = (element, className, submenuClassName) => {
    const scrollPosition = window.scrollY || window.pageYOffset;

    if (scrollPosition > lastScrollPosition) {
        element.classList.add(className);
        document.querySelectorAll(`.${submenuClassName}`).forEach((submenu) => {
            if (submenu.classList.contains('active')) {
                submenu.classList.remove('active');
            }
        })
    } else {
        element.classList.remove(className);
        
    }

    lastScrollPosition = scrollPosition <= 0 ? 0 : scrollPosition;
}