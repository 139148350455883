export default function ContentPopups() {
    const openPopupButtons = document.querySelectorAll('.button[data-content-popup-id]'),
        closePopupButtons = document.querySelectorAll('button[data-close-popup-id]');
    
        closePopupButtons.forEach((el) => {
            el.addEventListener('click', () => handlePopup(el.getAttribute('data-close-popup-id'), 'close'));
        })
        openPopupButtons.forEach((el) => {
            el.addEventListener('click', () => handlePopup(el.getAttribute('data-content-popup-id'), 'open'));
        })
    
    const handlePopup = (popupID, type) => {
        if (type == 'open') {
            document.querySelector(`.popup-content[data-content-popup-id="${popupID}"]`).classList.add('active');
        }
        if (type == 'close') {
            document.querySelector(`.popup-content[data-content-popup-id="${popupID}"]`).classList.remove('active');
        }
    } 

} 
