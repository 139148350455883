import HeaderMenu from "./components/header-menu";
import HeaderSubMenu from "./components/header-submenu";
import ContentPopups from "./components/popup-content";
import animateElements from "./components/image-animates";

var documentReady = function () {
    HeaderMenu();
    animateElements();
    if (document.querySelectorAll('[data-submenu-id]').length > 0 ) {
        HeaderSubMenu();
    }
    if (document.querySelectorAll('[data-content-popup-id]').length > 0 ) {
        ContentPopups();
    }
    if (document.querySelector('.js-show-cookies-banner')) {
        const showCookiesBanner = document.querySelector('.js-show-cookies-banner');
        showCookiesBanner.addEventListener('click', (e) => {
            e.preventDefault();
            if (document.querySelector('.cmplz-cookiebanner')) {
                document.querySelector('.cmplz-cookiebanner').classList.remove('cmplz-dismissed')
                document.querySelector('.complz-cookiebanner').classList.add('cmplz-show');
            }
        })
    }
};

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener("DOMContentLoaded", documentReady);
}
