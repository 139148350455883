export default function animateElements() {
    function isElementInView(el) {
        var rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }
  
    function animateVisibleImages() {
        var images = document.querySelectorAll('.background-corners__bottom-right, .background-corners__bottom-left, .background-corners__top-left, .background-corners__top-right');
        images.forEach(function(image) {
            if (isElementInView(image)) {
                image.classList.add('animate');
            }
        });
    }
  
    window.addEventListener('scroll', function() {
        animateVisibleImages();
    });
    
    window.addEventListener('load', function() {
        animateVisibleImages();
    });
}
  