export default function HeaderSubMenu() {
    const   parentMenuClass = 'header__menu__elements',
            submenuClass = 'header__submenu',
            submenuDataName = 'data-submenu-id',
            submenuButtons = document.querySelectorAll(`.${parentMenuClass} [${submenuDataName}], .button[${submenuDataName}]`),
            activeClass = 'active';

    submenuButtons.forEach((button) => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            let submenuItems = document.querySelector(`.${submenuClass}[${submenuDataName}="${button.getAttribute(submenuDataName)}"]`);
            if (submenuItems.getAttribute('data-opened') == 'false') {
                submenuItems.classList.add(activeClass);
                submenuItems.setAttribute('data-opened', 'true')
            } 
            else {
                submenuItems.classList.remove(activeClass);
                submenuItems.setAttribute('data-opened', 'false')
            }
        })
    })
}